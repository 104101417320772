<template>
  <q-table
    row-key="id"
    :rows-per-page-label="$t('Rows per page')"
    :rows="items"
    :columns="columns"
    v-model:pagination="pagination"
    :loading="isLoading"
    :filter="filter"
    :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
    virtual-scroll
    binary-state-sort
    @request="onRequest"
  >
    <template v-slot:body="props">
      <q-tr
        :props="props"
        class="clickable"
      >
        <q-td
          key="id"
          :props="props"
        >
          <strong>{{ props.row.id }}</strong>

          <div>
            {{ $moment(typeof props.row.created === 'object' ? props.row.created.date : props.row.created).format(appOptions.formats.date) }}
          </div>
        </q-td>

        <q-td
          key="state"
          :props="props"
        >
          <q-badge
            :color="stateColors[props.row.state]"
            text-color="dark"
            class="q-pa-sm"
          >
            {{ $t(props.row.state) }}
          </q-badge>
        </q-td>

        <q-td
          key="name"
          :props="props"
          style="max-width: 200px; min-width: 170px; white-space: pre-wrap !important;"
        >
          <div class="text-subtitle2 q-my-xs">
            <span>{{ props.row.handler }}</span>
          </div>

          <div v-if="props.row.name" class="q-mb-xs text-caption">
            <span>{{ props.row.name }}</span>
          </div>

          <div class="text-caption">
            <span>{{ $t('Begining') }} -</span>

            <span>
              {{ $moment(typeof props.row.beginningPeriod === 'object' ? props.row.beginningPeriod.date : props.row.beginningPeriod).format(appOptions.formats.date) }}
            </span>
          </div>

          <div class="text-caption">
            <span>{{ $t('End') }} -</span>

            <span>
              {{ $moment(typeof props.row.endPeriod === 'object' ? props.row.endPeriod.date : props.row.endPeriod).format(appOptions.formats.date) }}
            </span>
          </div>
        </q-td>

        <q-td
          key="sum"
          :props="props"
        >
          <span v-if="props.row.result && props.row.result.total && props.row.result.total.payment">
            <strong>{{ props.row.result.total.payment.toFixed(2) }}</strong><br>
          </span>

          <span v-else>
            <strong>-</strong><br>
          </span>
        </q-td>

        <q-td
          key="owner"
          :props="props"
        >
          <span v-if="props.row._embedded && props.row._embedded.owner">
            {{ props.row._embedded.owner.name }}
          </span>
        </q-td>

        <q-td
          key="documents"
          :props="props"
        >
          <q-btn-dropdown
            color="dark"
            size="sm"
            :label="$t('Download')"
            :disable="!props.row.data"
          >
            <q-list v-if="props.row.data">
              <q-item
                v-for="template in getTemplates(props.row.data)"
                :key="template.id"
                v-close-popup
                clickable
                @click="handleTemplate(template)"
              >
                <q-item-section>
                  <q-item-label>
                    {{ `${template.name || $t('No name')} (${template.id})` }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'WarehouseReportsTable',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      query: {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'reportType', value: 7 }
        ],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Details'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Total'),
          name: 'sum',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Documents'),
          name: 'documents',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'default',
        inactive: 'dark'
      },
      isLoading: false,
      items: []
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleTemplate (template) {
      return this.$service.printer.createWindowFromTemplate(template)
        .catch(err => {
          this.addErrorNotification(err)
        })
    },
    getTemplates (data) {
      if (data.files) {
        return Object.entries(data.files || {}).map(([id, file]) => ({ id, file }))
      }

      if (data.templates) {
        if (Array.isArray(data.templates)) {
          return data.templates
        }

        return Object.values(data.templates)
      }

      if (data.documents) {
        return data.documents
      }

      return []
    },
    loadDefaultItems () {
      const pagination = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'reportType', value: 7 }
        ],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      return this.$service.report.getAll(query)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
