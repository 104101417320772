<template>
    <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'TransactionWarehouse',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      entityTypes: [
        { id: 'Orderadmin\\Storage\\Entity\\Warehouse', name: 'Warehouse' },
      ],
      tax: 0,
      isEnabledValue: true,
      model: {
        account: null,
        rateModifier: null,
        entityType: null,
        entity: null,
        value: null,
        transactionDate: null,
        comment: null
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                disabled: !!this.model.rateModifier && !this.isEnabledValue,
                type: 'input',
                inputType: 'number',
                label: this.$t('Value'),
                field: 'value',
                value: this.model.value,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                required: true,
                onChange: value => {
                  this.model.value = value
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                field: 'comment',
                value: this.model.comment,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: value => {
                  this.model.comment = value
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.tax = this.data.tax || 0

    this.model = {
      ...this.model,
      ...this.data,
      tax: 0
    }
  },
  methods: {
    getErrors () {
      return this.schema.groups[0].fields.reduce((acc, field) => {
        if (field.required && !field.value) {
          acc.push(`${field.label} is required!`)
        }

        return acc
      }, [])
    },
    getModel () {
      return this.model
    }
  }
}
</script>
