<template>
  <q-dialog v-model="isOpen">
    <q-card :style="`min-width: ${width}vw; overflow: visible;`">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('New transaction') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <transaction-warehouse ref="transaction" :data="defaultData" />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          size="sm"
          :label="$t('Close')"
          color="dark"
          @click="close"
        />

        <q-btn
          size="sm"
          :label="$t('Save')"
          color="light-blue-9"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import {mapActions, mapMutations} from 'vuex'

// Components
import TransactionWarehouse from '../transactions/TransactionWarehouse.vue'
import {buildQuery} from "@/apps/app/utils/query-utils";

export default {
  name: 'TransactionWarehouseModal',
  emits: ['submit'],
  components: {
    TransactionWarehouse
  },
  props: {
    warehouse: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      isOpen: false,
      defaultData: {},
      pagination: {},
    }
  },
  computed: {
    width () {
      return window.innerWidth < 1200
        ? 80
        : 40
    }
  },
  methods: {
    ...mapMutations([
      'upsertTransaction',
      'addErrorNotification'
    ]),
    ...mapActions([
      'loadAccounts'
    ]),
    close () {
      this.isOpen = false
    },
    open (defaultData = {}) {
      this.defaultData = defaultData
      this.isOpen = true
    },
    save () {
      const errors = this.$refs.transaction.getErrors()

      if (errors.length > 0) {
        errors.forEach(this.addErrorNotification)
        return
      }

      const model = Object.entries(this.$refs.transaction.getModel()).reduce((acc, [key, value]) => {
        acc[key] = value && typeof value === 'object' && value.id
          ? value.id
          : value

        return acc
      }, {})

      return this.$service.transaction.save(model)
        .then(item => {
          this.upsertTransaction(item)
          this.close()
          this.$emit('submit', item)
          return item
        })
    }
  }
}
</script>
