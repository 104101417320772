<template>
  <q-dialog
    v-model="isOpen"
    :maximized="false"
    content-style="overflow: visible;"
    :class="slide === 'reports' ? 'modal-exl' : 'modal-md'"
  >
    <q-card
      class="relative"
      :style="slide !== 'reports' ? `overflow: visible;` : ''"
    >
      <q-card-section class="row items-center no-wrap">
        <q-btn
          v-if="slide !== 'reports'"
          text-color="dark"
          size="sm"
          icon="arrow_back"
          class="q-mr-sm"
          no-caps
          flat
          unelevated
          @click="handleBack"
        />

        <h5 class="q-my-none">
          {{ $t('Warehouse reports') }}
        </h5>

        <q-btn
          v-if="slide === 'reports'"
          color="light-blue-9"
          text-color="white"
          size="sm"
          class="q-ml-sm"
          no-caps
          :label="$t('Add')"
          unelevated
          @click="handleAdd"
        />

        <q-space />

        <q-btn
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <div v-if="slide === 'add'">
          <create-warehouse-report @submit="handleSubmit" />
        </div>

        <div v-if="slide === 'reports'">
          <warehouse-reports-table />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import WarehouseReportsTable from './WarehouseReportsTable'
import CreateWarehouseReport from './CreateWarehouseReport'

// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'WarehouseReports',
  components: {
    WarehouseReportsTable,
    CreateWarehouseReport
  },
  data () {
    return {
      isOpen: false,
      slide: 'reports',
      report: null
    }
  },
  computed: {
    width () {
      const sizes = {
        reports: 60,
        report: 60,
        add: 40
      }

      if (!sizes[this.slide]) {
        return 40
      }

      return sizes[this.slide]
    }
  },
  methods: {
    ...mapMutations([
      'removeReportFromList',
      'addReportFromList'
    ]),
    handleSubmit () {
      this.slide = 'reports'
    },
    handleAdd () {
      this.slide = 'add'
    },
    handleClick (item) {
      this.report = item
      this.slide = 'report'
    },
    handleBack () {
      this.slide = 'reports'
      this.report = null
    },
    open () {
      this.slide = 'reports'
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  }
}
</script>
