<template>
  <div>
    <form-builder :schema="schema" />

    <div class="text-center q-pt-sm">
      <q-btn
        color="light-blue-9"
        text-color="white"
        no-caps
        :label="$t('Create')"
        unelevated
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'CreateWarehouseReport',
  emits: ['submit'],
  data () {
    return {
      report: {
        beginningPeriod: '',
        endPeriod: '',
        state: [],
        warehouse: []
      },
      statuses: [
        { code: 'new', name: 'new' },
        { code: 'normal', name: 'normal' },
        { code: 'blocked', name: 'blocked' },
        { code: 'booked', name: 'booked'},
        { code: 'shipped', name: 'shipped' },
        { code: 'returned', name: 'returned' },
        { code: 'expected', name: 'expected' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'warehouses',
      'warehousePage',
      'warehouseTotalPages'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'date',
                value: this.report.beginningPeriod,
                label: this.$t('Beginning'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: (value) => {
                  this.report.beginningPeriod = value
                }
              },
              {
                type: 'date',
                value: this.report.endPeriod,
                label: this.$t('End'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: (value) => {
                  this.report.endPeriod = value
                }
              },
              {
                type: 'tag',
                label: this.$t('Status'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'state',
                value: this.report.state,
                options: this.statuses,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                    ? this.$t(row.name)
                    : row
                },
                customListItem: (row) => {
                  return row && typeof row === 'object'
                    ? this.$t(row.name)
                    : row
                },
                onRemove: (val) => {
                  this.report.state = this.report.state.filter(x => x !== val)
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.report.state = this.report.state.filter(val => {
                    if (val === value.code) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.report.state = [...this.report.state, value.code]
                }
              },
              {
                type: 'tag',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.report.warehouse,
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                customTag: (item) => {
                  if (item && typeof item === 'object') {
                    return item.name
                  }

                  return item
                },
                customListItem: (item) => {
                  if (item && typeof item === 'object') {
                    return item.name
                  }

                  return item
                },
                onRemove: (val) => {
                  this.report.warehouse = this.report.warehouse.filter(x => x.id !== val.id)
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let hasValue = false

                  const warehouse = this.report.warehouse.filter(val => {
                    if (val.id === value.id) {
                      hasValue = true
                      return false
                    }

                    return true
                  })

                  if (hasValue) {
                    this.report.warehouse = warehouse
                    return
                  }

                  this.report.warehouse = [...this.report.warehouse, value]
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    save () {
      const data = {
        handler: 'storage-warehouse-availability',
        reportType: 7,
        beginningPeriod: this.report.beginningPeriod,
        endPeriod: this.report.endPeriod,
        settings: {
          state: this.report.state,
          warehouse: this.report.warehouse.map(({ id }) => id)
        }
      }

      return this.$service.report.save(data)
        .then(report => {
          this.$emit('submit', report)
        })
    }
  }
}
</script>
